import React, { useRef } from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import Translate from "../../config/Translate";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";
const AnimalPopUp = ({ isAvailable }) => {
  const hideRef = useRef();
  const setFirstTimeFallBack = () => {
    hideRef.current.style.display = "none";
  };
  return (
    <PopContainer ref={hideRef}>
      <Fade>
        <ModalAction>
          <Top>
            <Title></Title>
            <Cross onClick={setFirstTimeFallBack}>
              <ClearIcon />
            </Cross>
          </Top>

          <Message>
            {isAvailable ? (
              <Link to={"/dashboard/pets"}>
                <Translate Word="page.petaddmessage.withanimal" />
              </Link>
            ) : (
              <>
              <Translate Word="page.petaddmessage.noanimal" />
                <Link to={"/dashboard/new"}>
                  <Translate Word="page.add.companion" />
                </Link>
              </>
            )}
          </Message>
        </ModalAction>
      </Fade>
    </PopContainer>
  );
};
export default AnimalPopUp;
const PopContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #00000075;
  display: flex;
  justify-content: center;
  z-index: 100000;
`;
const Title = styled.h1`
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
  text-transform: uppercase;
  color: #79aec1;
`;
const Message = styled.p`
  color: rgba(0, 0, 0, 0.85);
  font-size: 13px;
  margin: 10px 0 20px 0px;
  font-size: 18px;
  text-align: justify;
`;
const Action = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
`;
const Button = styled.button`
  width: 80px;
  padding: 10px;
  border: none;
  background: #79aec1;
  border-radius: 4px;
  font-weight: 600;
  color: white;
`;
const ModalAction = styled.div`
  min-height: 200px;
  width: 600px;
  background: whitesmoke;
  border-radius: 4px;
  border: none;
  margin-top: 200px;
  text-align: center;
  padding: 20px;
  @media (max-width: 650px) {
    width: 350px;
  }
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const Cross = styled.div`
  cursor: pointer;
  color: gray;
`;
